import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SellerInfoComponent } from './application/pages/seller-info/seller-info.component';
import { BankDetailsComponent } from './application/pages/bank-details/bank-details.component';
import { DashboardComponent } from './application/pages/dashboard/dashboard.component';
import { ResidencesComponent } from './application/pages/residences/residences.component';
import { WarehousesComponent } from './application/pages/warehouses/warehouses.component';
import { ImportComponent } from './application/pages/import/import.component';
import { Step1Component } from './application/pages/import/step1/step1.component';
import { Step2Component } from './application/pages/import/step2/step2.component';
import { Step3Component } from './application/pages/import/step3/step3.component';
import { PriceListComponent } from './application/pages/price-list/price-list.component';
import { LoginComponent } from './application/pages/login/login.component';
import { CurrentResolver } from './application/current.resolver';
import { IntegrationComponent } from './application/pages/integration/integration.component';
import { BrandMappingComponent } from './application/pages/mapping/brand-mapping.component';
import { NewItemsComponent } from './application/pages/orders/new-items/new-items.component';
import { ProcessingItemsComponent } from './application/pages/orders/processing-items/processing-items.component';
import { PackedItemsComponent } from './application/pages/orders/packed-items/packed-items.component';
import { StockPricesComponent } from './application/pages/stock-prices/stock-prices.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {unLogged: true}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    resolve: {  currentSeller: CurrentResolver },
  },
  {
    path: 'products',
    children: [
      {path: '', redirectTo: 'price_list', pathMatch: 'prefix'},
      {path: 'price_list', component: PriceListComponent,},
      {path: 'stock_prices', component: StockPricesComponent,},
      {path: 'mapping', component: BrandMappingComponent,},
      {
        path: 'import',
        component: ImportComponent,
        children: [
          { path: '', redirectTo: 'step1', pathMatch: 'prefix' },
          { path: 'step1', component: Step1Component, data: { stepId: 1 } },
          { path: 'step2', component: Step2Component, data: { stepId: 2 } },
          { path: 'step3', component: Step3Component, data: { stepId: 3 } },
        ],
      },
    ],
    resolve: {  currentSeller: CurrentResolver },
  },
  {
    path: 'settings',
    children: [
      {path: '', redirectTo: 'seller_info', pathMatch: 'prefix'},
      {path: 'seller_info', component: SellerInfoComponent},
      {path: 'integration', component: IntegrationComponent},
      {path: 'bank_details', component: BankDetailsComponent},
      {path: 'residences', component: ResidencesComponent},
      {path: 'warehouses', component: WarehousesComponent},
    ],
    resolve: {  currentSeller: CurrentResolver },
  },
  {
    path: 'orders',
    children: [
      {path: '', redirectTo: 'new', pathMatch: 'prefix'},
      {path: 'new', component: NewItemsComponent},
      {path: 'processing', component: ProcessingItemsComponent},
      {path: 'packed', component: PackedItemsComponent},
    ],
    resolve: {  currentSeller: CurrentResolver },
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
