import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractForm} from './abstract.form';
import {Offer} from '../../domain/entity/offer/offer.entity';

export class ProcessPriceForm extends AbstractForm {
  minPrice = 1;
  minQty = 0;
  minDays = 0;
  pricePattern = '^(\\d+)?(\\.)?(\\d{0,2})?$';
  maxPrice: number;
  maxPurchasePrice: number;
  setPriceControl: (type: ('price' | 'purchasePrice')) => void;
  private qtyValidators = [Validators.min(this.minQty) as any];

  constructor(public holdQty?: boolean, public offer?: Offer, private disableChangeEnabled?: boolean) {
    super();
    this.maxPrice = offer?.mrp / 100 || 0;
    this.maxPurchasePrice = offer?.price / 100 || offer?.mrp / 100 || 0;
    this.form = new UntypedFormGroup({
      partNumber: new UntypedFormControl(offer?.partNumber || '', [
        Validators.required as any,
      ]),
      brand: new UntypedFormControl(offer?.brand || '', [
        Validators.required as any,
      ]),
      name: new UntypedFormControl(offer?.name || '', []),
      price: new UntypedFormControl(offer?.price / 100 || '', [
        Validators.required as any,
        Validators.min(this.minPrice) as any,
        Validators.max(this.maxPrice) as any,
        Validators.pattern(this.pricePattern) as any,
      ]),
      purchasePrice: new UntypedFormControl(offer?.purchasePrice / 100 || '', [
        Validators.required as any,
        Validators.min(this.minPrice) as any,
        Validators.max(this.maxPurchasePrice) as any,
        Validators.pattern(this.pricePattern) as any,
      ]),
      mrp: new UntypedFormControl(offer?.mrp / 100 || '', [
        Validators.required as any,
        Validators.min(this.minPrice) as any,
        Validators.pattern(this.pricePattern) as any,
      ]),
      qty: new UntypedFormControl(offer?.qty || this.minQty, holdQty
        ? this.qtyValidators
        : [...this.qtyValidators, Validators.required as any]),
      enabled: new UntypedFormControl({
          value : offer ? offer?.enabled : true,
          disabled: this.disableChangeEnabled
      }),
      ignored: new UntypedFormControl(offer ? offer?.ignored : false),
      dispatchDays: new UntypedFormControl(offer?.dispatchDays, [
        Validators.min(this.minDays) as any,
      ]),
    });

    this.setPriceControl = (type: 'price' | 'purchasePrice'): void => {
      this.maxPrice = this.form.get('mrp').value || 1;
      this.maxPurchasePrice = this.form.get('price').value;
      this.form.get(type).setValidators([
        Validators.required as any,
        Validators.pattern(this.pricePattern) as any,
        Validators.min(this.minPrice) as any,
        Validators.max(type === 'purchasePrice'
          ? this.maxPurchasePrice
          : this.maxPrice) as any
      ]);
      this.form.get(type).updateValueAndValidity({onlySelf: false, emitEvent: true });
    };
  }
}
