import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import md5 from 'md5';
import { BUILD, VERSION } from '../../../environments/version';
import { FingerPrintService } from '../services/finger-print.service';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private fingerPrintService: FingerPrintService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addCustomHeaders(request);

        if (this.authService.hasLocalToken()) {
            const token = this.authService.getToken();
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`),
            });
        }
        return next.handle(request);
    }

    public addCustomHeaders(req: HttpRequest<any>): HttpRequest<any> {
        const now = new Date();
        const timestamp = Math.floor(now.getTime() / 1000);
        const customHeaders = {
            'X-Client-App': 'seller_cabinet',
            'X-Client-Build': BUILD,
            'X-Client-Version': VERSION,
            'X-Date': now.toISOString(),
            'X-Boo-Sign': md5(
              this.fingerPrintService.getVisitorId()
                ? timestamp + ' ' + this.fingerPrintService.getVisitorId()
                : (1000 * Math.floor(timestamp / 1000)).toString()
            ),
        };

        if (this.fingerPrintService.getVisitorId()) {
            customHeaders['X-Client-Id'] = this.fingerPrintService.getVisitorId();
        }

        if (this.getApiVersion(req.url)) {
            customHeaders['Accept-Version'] = this.getApiVersion(req.url);
        }

        if (this.getApiModule(req.url)) {
            customHeaders['X-Api'] = this.getApiModule(req.url);
        }


        return req.clone({
            setHeaders: customHeaders,
        });
    }

    public getApiVersion(url: string): string {
        const apiVersionPath = url.match(/\/v\d\//);

        if (!apiVersionPath?.length) {
            return '';
        }

        return apiVersionPath[0].match(/v\d/)[0];
    }

    public getApiModule(url: string): string {
        let apiModule = '';

        switch (true) {
            case url.includes('/seller/api/'):
                apiModule = 'SellerAPI';
                break;
        }

        return apiModule;
    }
}
