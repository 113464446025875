import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from '../services/toastr.service';
import { AbstractRepository } from '../../infrastructure/repositories/abstract.repository';

@Injectable({
  providedIn: 'root',
})

export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastrService: ToastrService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        console.log(error);
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Status: ${error.status}\nMessage: ${error?.error?.detail ?? error?.message}`;
          if (error.status === 503) {
            this.toastrService.errorNotify(
              'Please, try after few minutes',
              {header: 'Website is temporary unavailable'}
            );
            return throwError('');
          }
          if (request.url === AbstractRepository.buildUrl('seller/login') && error.status === 500) {
            this.toastrService.errorNotify('Incorrect username or password. Please, check entered data');
            return throwError('');
          }
        }
        this.toastrService.errorNotify(errorMessage);
        return throwError(error);
      })
    );
  }
}
