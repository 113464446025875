import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map } from 'rxjs/operators';
import { AuthRepository } from '../../infrastructure/repositories/auth.repository';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from './toastr.service';
import { CurrentSeller } from '../../domain/entity/current-seller/current-seller.entity';

const DAYS_LIMIT = 7;

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  redirectUrl: string;
  private readonly TOKEN_CONST = 'seller_token';
  private readonly ADMIN_EMAIL = 'admin_email';


  constructor(
    private authRepository: AuthRepository,
    private cookieService: CookieService,
    private router: Router,
    private toastrService: ToastrService,
  ) {
  }

  currentUser = (): Observable<CurrentSeller> => this.authRepository.currentUser().pipe(
    map(CurrentSeller.build),
    catchError(err => throwError(err))
  );

  loginUser = (email: string, password: string): Observable<{ token: string }> =>
    this.authRepository.loginUser(email, password).pipe(
      catchError(err => throwError(err))
    );

  getAdminEmail(): string
  {
    return this.cookieService.get(this.ADMIN_EMAIL);
  }

  registerUser = (email: string, password: string): Observable<{ token: string }> =>
    this.authRepository.registerUser(email, password).pipe(
      catchError(err => throwError(err))
    );

  // oauth = (username: string, password: string): Observable<any> => this.authRepository.oauth(username, password);

  subscribeUser(jwtToken: string): void {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + DAYS_LIMIT);
    this.cookieService.set(this.TOKEN_CONST, jwtToken, expireDate);
  }

  hasLocalToken(): boolean {
    return this.cookieService.get(this.TOKEN_CONST) !== null;
  }

  checkToken(): boolean {
    return this.cookieService.check(this.TOKEN_CONST);
  }

  removeToken(): void {
    if (this.hasLocalToken()) {
      this.cookieService.delete(this.TOKEN_CONST);
    }
  }

  getToken(): string {
    return this.cookieService.get(this.TOKEN_CONST);
  }

  public logout(): void {
    this.removeToken();
    this.router.navigate(['/login']);
  }
}
