import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { NgxPaginationModule } from 'ngx-pagination';

import {AppRoutingModule} from './app-routing.module';
import { AppComponent } from './application/components/app/app.component';
import { ConfirmModalComponent, ConfirmService, ConfirmState, ConfirmTemplateDirective } from './application/services/confirm-modal.service';
import { BROWSER_FAVICONS_CONFIG, BrowserFavicons, Favicons } from './application/services/browser-favicons.service';
import { ToastrContainerComponent } from './application/components/toastr-container/toastr-container.component';
import { SidebarComponent } from './application/components/app/sidebar/sidebar.component';
import { BankDetailsComponent } from './application/pages/bank-details/bank-details.component';
import { SellerInfoComponent } from './application/pages/seller-info/seller-info.component';
import { DashboardComponent } from './application/pages/dashboard/dashboard.component';
import { ResidencesComponent } from './application/pages/residences/residences.component';
import { WarehousesComponent } from './application/pages/warehouses/warehouses.component';
import { SafeHtmlPipe } from './application/pipes/safe-html.pipe';
import { FileUploaderDirective } from './application/directives/file-uploader/file-uploader.directive';
import { IndianCurrencyPipe } from './application/pipes/indian-currency/indian-currency.pipe';
import { BdatePipe } from './application/pipes/bdate/bdate.pipe';
import { WarehouseDetailsComponent } from './application/pages/warehouses/warehouse-details/warehouse-details.component';
import { ProcessWarehouseComponent } from './application/pages/warehouses/process-warehouse/process-warehouse.component';
import { BreadcrumbsComponent } from './application/components/breadcrumbs/breadcrumbs.component';
import { ImportComponent } from './application/pages/import/import.component';
import { Step1Component } from './application/pages/import/step1/step1.component';
import { Step2Component } from './application/pages/import/step2/step2.component';
import { Step3Component } from './application/pages/import/step3/step3.component';
import { MappingComponent } from './application/pages/import/step2/mapping/mapping.component';
import { AutofocusDirective } from './application/directives/autofocus/autofocus.directive';
import { PriceListComponent } from './application/pages/price-list/price-list.component';
import { BooleanLabelPipe } from './application/pipes/boolean-label/boolean-label.pipe';
import { LoaderComponent } from './application/components/loader/loader.component';
import { InlineLoaderComponent } from './application/components/inline-loader/inline-loader.component';
import { ThComponent } from './application/components/th/th.component';
import { PartLinkComponent } from './application/components/part-link/part-link.component';
import { BuildPartLinkPipe } from './application/pipes/build-part-link/build-part-link.pipe';
import { PriceDetailsComponent } from './application/pages/price-list/price-details/price-details.component';
import { LoginComponent } from './application/pages/login/login.component';
import { TokenInterceptor } from './application/interceptors/token.interceptor';
import { ErrorInterceptor } from './application/interceptors/error.interceptor';
import { ImportDetailsComponent } from './application/pages/dashboard/import-details/import-details.component';
import { ResidenceDetailsComponent } from './application/pages/residences/residence-details/residence-details.component';
import { ProcessResidenceComponent } from './application/pages/residences/process-residence/process-residence.component';
import { IntegrationComponent } from './application/pages/integration/integration.component';
import { ProcessMappingComponent } from './application/pages/mapping/process-mapping/process-mapping.component';
import { BrandMappingComponent } from './application/pages/mapping/brand-mapping.component';
import { ProcessPriceComponent } from './application/pages/price-list/process-price/process-price.component';
import { NumericDirective } from './application/directives/numeric/numeric.directive';
import { ProfilePanelComponent } from './application/components/app/profile-panel/profile-panel.component';
import {CustomAdapter} from './application/utils/date/date.adapter';
import {CustomDateParserFormatter} from './application/utils/date/date.parser';
import { TfootComponent } from './application/components/tfoot/tfoot.component';
import {NewItemsComponent} from './application/pages/orders/new-items/new-items.component';
import {ProcessingItemsComponent} from './application/pages/orders/processing-items/processing-items.component';
import {PackedItemsComponent} from './application/pages/orders/packed-items/packed-items.component';
import {NewItemDetailsComponent} from './application/pages/orders/new-items/new-item-details/new-item-details.component';
import { CancelConfirmationComponent } from './application/pages/orders/popups/cancel-confirmation/cancel-confirmation.component';
import { ProcessingItemDetailsComponent } from './application/pages/orders/processing-items/processing-item-details/processing-item-details.component';
import { ProcessingOrderDetailsComponent } from './application/pages/orders/processing-items/processing-order-details/processing-order-details.component';
import { ShipmentDetailsComponent } from './application/pages/orders/popups/shipment-details/shipment-details.component';
import {MaxNumberDirective} from './application/directives/max-number/max-number.directive';
import { PackedItemDetailsComponent } from './application/pages/orders/packed-items/packed-item-details/packed-item-details.component';
import { InvalidDirective } from './application/directives/invalid.directive';
import { StockPricesComponent } from './application/pages/stock-prices/stock-prices.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { LinkOfferComponent } from './application/pages/price-list/link-offer/link-offer.component';
import {GstControlComponent} from './application/components/gst-control/gst-control.component';
import {PinControlComponent} from './application/components/pin-control/pin-control.component';
import {FormControlErrorComponent} from './application/components/form-control-error/form-control-error.component';
import {ExportResultsComponent} from './application/components/export-results/export-results.component';
import {OrderByPipe} from './application/pipes/order-by/order-by.pipe';
import {FilterByPipe} from './application/pipes/filter-by/filter-by.pipe';

@NgModule({
  declarations: [
    AppComponent,
    ToastrContainerComponent,
    ConfirmModalComponent,
    ConfirmTemplateDirective,
    SidebarComponent,
    BankDetailsComponent,
    SellerInfoComponent,
    DashboardComponent,
    ResidencesComponent,
    WarehousesComponent,
    SafeHtmlPipe,
    FileUploaderDirective,
    IndianCurrencyPipe,
    OrderByPipe,
    FilterByPipe,
    BdatePipe,
    WarehouseDetailsComponent,
    ProcessWarehouseComponent,
    BreadcrumbsComponent,
    ImportComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    MappingComponent,
    AutofocusDirective,
    PriceListComponent,
    BooleanLabelPipe,
    LoaderComponent,
    InlineLoaderComponent,
    ThComponent,
    PartLinkComponent,
    BuildPartLinkPipe,
    PriceDetailsComponent,
    LoginComponent,
    ImportDetailsComponent,
    ResidenceDetailsComponent,
    ProcessResidenceComponent,
    IntegrationComponent,
    BrandMappingComponent,
    ProcessMappingComponent,
    ProcessPriceComponent,
    NumericDirective,
    ProfilePanelComponent,
    TfootComponent,
    NewItemsComponent,
    ProcessingItemsComponent,
    PackedItemsComponent,
    NewItemDetailsComponent,
    CancelConfirmationComponent,
    ProcessingItemDetailsComponent,
    ProcessingOrderDetailsComponent,
    ShipmentDetailsComponent,
    MaxNumberDirective,
    PackedItemDetailsComponent,
    InvalidDirective,
    StockPricesComponent,
    LinkOfferComponent,
    GstControlComponent,
    PinControlComponent,
    FormControlErrorComponent,
    ExportResultsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    NgSelectModule,
  ],
  exports: [
    ToastrContainerComponent,
    NgbModule,
  ],
  providers: [
    CookieService,
    ConfirmService,
    ConfirmState,
    IndianCurrencyPipe,
    BdatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: Favicons, useClass: BrowserFavicons},
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          prod: {
            type: 'image/png',
            href: './assets/img/favicon.png',
            isDefault: true
          },
          develop: {
            type: 'image/png',
            href: './assets/img/favicon_dev.png'
          },
        },
        cacheBusting: true
      }
    },
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}],
  bootstrap: [AppComponent]
})
export class AppModule { }
